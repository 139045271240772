<template>
    <v-card height="100">
        <v-card-text>
            <h2>Properties</h2>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "account-properties",
    data() {
        return {};
    },
};
</script>